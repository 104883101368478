<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <!-- 설비등급 변경이력 -->
      <c-table
        ref="equipmentGradeTable"
        title="LBL0003314"
        tableId="equipmentGradeTable"
        :columns="grid.columns"
        :data="grid.data"
        @rowClick="rowClick"
        :hide-bottom="true"
        :hide-pagination="true"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm">
        <!-- 설비등급 변경정보 -->
        <c-card title="LBL0003315" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- 등록 -->
              <c-btn v-if="editable && addMode" label="LBLREG" icon="add" @btnClicked="addRank" />
              <!-- 개정 -->
              <c-btn v-if="editable && revisionMode" label="LBLREV" icon="restart_alt" @btnClicked="revisionRank" />
              <!-- 저장 -->
              <c-btn
                v-if="editable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="data"
                :mappingType="saveType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveRank"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-4">
              <!-- 위험등급 -->
              <c-select
                :required="true"
                codeGroupCd="MDM_EQUIP_GRADE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="grade"
                label="LBL0003316"
                v-model="data.grade"
                :editable="editable && updateMode"
              ></c-select>
            </div>
            <div class="col-4">
              <!-- 등급변경일 -->
              <c-datepicker
                :required="true"
                :editable="editable && updateMode"
                label="LBL0003317"
                name="gradeDate"
                v-model="data.gradeDate"
              />
            </div>
            <div class="col-4">
              <!-- 평가점수 -->
              <c-text
                :required="true"
                :editable="editable && updateMode"
                label="LBL0003318"
                type="number"
                name="estimationScore"
                v-model="data.estimationScore">
              </c-text>
            </div>
            <div class="col-12">
              <!-- 등급변경사유 -->
              <c-textarea
                :required="true"
                :editable="editable && updateMode"
                label="LBL0003319"
                name="changeReason"
                v-model="data.changeReason">
              </c-textarea>
            </div>
            <div class="col-12">
              <!-- 설비중요도 등급평가표 -->
              <c-upload
                :attachInfo="attachInfo"
                :editable="editable && updateMode"
                label="설비중요도 등급평가표">
              </c-upload>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>
1
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-grade',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EQUIPMENT_GRADE',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'gradeDate',
            field: 'gradeDate',
            // 등급변경일
            label: 'LBL0003317',
            align: 'center',
            sortable: false,
          },
          {
            name: 'gradeName',
            field: 'gradeName',
            // 설비등급
            label: 'LBL0003321',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        equipmentCd: '',
        recordNo: '',
        grade: null,
        estimationScore: '',
        gradeDate: '',
        changeReason: '',
        regUserId: '',
        chgUserId: '',
      },
      listUrl: '',
      detailUrl: '',
      editable: true,
      updateMode: false,
      revisionMode: false,
      addMode: false,
      isSave: false,
      saveUrl: '',
      saveType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.saveUrl = transactionConfig.mdm.equipment.rank.save.url;
      this.listUrl = selectConfig.mdm.equipment.rank.list.url;
      this.detailUrl = selectConfig.mdm.equipment.rank.get.url;
      this.saveUrl = transactionConfig.mdm.equipment.rank.save.url;
      
      this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
      // list scope
      this.getRankList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getRankList() {
      this.getList();
      this.rowRemoveSelect();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.param.plantCd,
        equipmentCd: this.param.equipmentCd,
      };
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.grid.data = _result.data;
          this.reset();
          this.addMode = false;
          this.updateMode = false;
        } else {
          this.reset();
          this.addMode = true;
        }
      },);
    },
    rowClick(row) {
      this.updateMode = false;
      this.$http.url = this.detailUrl;
      this.$http.param = {
        plantCd: row.plantCd,
        equipmentCd: row.equipmentCd,
        recordNo: row.recordNo,
      };
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.editFlag = 'U';
        this.revisionMode = true;
      },
      () => {
      });
    },
    addRank() {
      this.updateMode = true;
      this.reset();
      this.data.gradeDate = this.$comm.getToday();
    },
    reset() {
      this.rowRemoveSelect();
      this.data = {
        editFlag: 'C',
        plantCd: this.param.plantCd,
        equipmentCd: this.param.equipmentCd,
        recordNo: '',
        grade: null,
        estimationScore: '',
        gradeDate: '',
        changeReason: '',
        regUserId: '',
        chgUserId: '',
      };
    },
    revisionRank() {
      this.rowRemoveSelect();
      this.data = {
        editFlag: 'R',
        plantCd: this.param.plantCd,
        equipmentCd: this.param.equipmentCd,
        recordNo: '',
        grade: null,
        estimationScore: '',
        gradeDate: this.$comm.getToday(),
        changeReason: '',
        regUserId: '',
        chgUserId: '',
      };
      this.updateMode = true;
    },
    saveRank() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.addMode = false;
      this.getRankList();
      this.$emit('rankChange');
    },
  }
};
</script>